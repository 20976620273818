// 引入axios
import axios from 'axios'
// 引入elementui的Message
import { Message } from 'element-ui'
// 引入路由实例对象
import router from "@/router"

import qs from 'qs'
// 配置服务器地址
// axios.defaults.baseURL = '/api'
axios.defaults.baseURL = process.env.VUE_APP_SERVE
// 超时时间
axios.defaults.timeout = 20000

// 请求拦截器
axios.interceptors.request.use(config => {
    //设置数据请求方法
    config.data = qs.stringify(config.data)
    config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    // 设置token
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = token
    }
    return config
}, err => {
    return Promise.reject(err)
})

// 响应拦截器
axios.interceptors.response.use(res => {
    if (res.data.code != 200) {
        // Message.success(res.data.message)
        Message.error(res.data.message)
    }
    return res
}, err => {
    if (err.code === 'ECONNABORTED') {
        if (document.getElementsByClassName('el-message').length === 0) {
        Message.error('request timeout')
        }
    }
    const { response } = err
    if (response && response.data && response.data.code === 401) {
        localStorage.removeItem('token')
        Message.warning('invalid token')
        router.push('/login')
    }
    return Promise.reject(err)
})

export default axios