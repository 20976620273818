<template>
    <div class="aside">
        <!-- 经销商的侧导航栏 -->
        <h1>OEM INFORMATION</h1>
        <div class="line"></div>
        <h2>{{obj.corporate_name}}</h2>
        <div class="info">
            <div class="address">
                <p><img src="@/assets/imgs/location.png" alt="">OEM Address</p>
                <p class="item">{{ obj.address }},{{ obj.city }}{{ '  '+obj.state }}{{' '+ obj.postal_code }}{{' '+  obj.country }}</p>
                <p class="item"></p>
                <p class="item"></p>
                <p class="item"></p>
                <p class="item"></p>
            </div>
            <p><img src="@/assets/imgs/tel.png" alt="">{{obj.mobile}}</p>
            <p><img src="@/assets/imgs/mail.png" alt="">{{obj.email}}</p>
            <p><img src="@/assets/imgs/network.png" alt="">{{ obj.website }}</p>
        </div>
        <div class="message" @click="tomessage">
            <div class="tag" v-if="num>0">{{num}}</div>
            <!-- <el-badge :value="3" class="item"> -->
            <img src="@/assets/imgs/message.png" width="20" alt="">
            <!-- </el-badge> -->
           Notifications
        </div>
        <div class="title">OEM TOOLS</div>
        <div class="side">
            <router-link :class="$route.path.includes('inventory')?'router-link-active':''" to="/inventory/records">Inventory Record</router-link>
            <router-link :class="$route.path.includes('retailer')?'router-link-active':''" to="/retailer/records">Wholesale Record</router-link>
            <!-- <router-link :class="$route.path.includes('warranty')?'router-link-active':''" to="/warranty/register">Warranty Registrations</router-link> -->
            <router-link :class="$route.path.includes('claim')?'router-link-active':''" to="/claim/compensate">Warranty Claims</router-link>
            <router-link :class="$route.path.includes('recall')?'router-link-active':''" to="/recall/list">Recall Center</router-link>
            <router-link :class="$route.path.includes('crm')?'router-link-active':''" to="/crm/list">CRM</router-link>
            <router-link :class="$route.path.includes('maintain')?'router-link-active':''" to="/maintain/service">Maintenance & Service </router-link>
            <router-link :class="$route.path.includes('contact')?'router-link-active':''" to="/contact">Contact Us </router-link>
            <router-link :class="$route.path.includes('data')?'router-link-active':''" to="/data/resources">OEM Resources </router-link>
            
           
        </div>
    </div>
</template>

<script>
    export default {
        created(){
        },
        props:{
            obj:{
                type:Object,
                default:()=>({})
            },
            num:{
                type:Number,
                default:0
            }
        },
        methods:{
            tomessage(){
                this.$router.push('/message')
            }
        }
    }
</script>

<style lang="less" scoped>
    .aside {
        margin-top: 19px;
        margin-left: 2.6042vw;
        box-shadow: 0 0 12px 0 rgba(0,0,0,.2);
        width: 15.3646vw;
        box-sizing: border-box;
        height: 95%;
        padding: 40px 20px;
        h1 {
            font-size: 18px;
            font-weight: 500;
        }
        .line {
            width: 100%;
            height: 2px;
            background: #969696;
            margin-top: 15px;
        }
        h2 {
            font-size: 12px;
            font-weight: 500;
            margin: 17px 0 12px;
        }
        .info {
            p {
                font-size: 12px;
                line-height: 20px;
                color: #3D3D3D;
                img {
                    margin-right: 10px;
                }
            }
            .address {
                .item{
                    padding-left: 20px;
                }
            }
        }
        .message {
            cursor: pointer;
            font-size: 12px;
            color: #3D3D3D;
            width: 100%;
            height: 36px;
            background: #EDEDED;
            line-height: 36px;
            padding-left: 16px;
            margin-top: 10px;
            position: relative;
            img {
                vertical-align: middle;
                margin-right: 10px;
            }
            .tag {
                padding: 0 5px;
                text-align: center;
                line-height: 16px;
                position: absolute;
                left: 30px;
                top: 2px;
                background: red;
                color: #fff;
                font-size: 12px;
                border-radius: 8px;
            }
        }
        .title {
            padding: 26px 0 13px; 
            font-size: 18px;
            font-weight: 600;
            color: #0F1423;
            border-bottom: 2px solid #969696;
        }
        .side {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 46px;
            a {
                border-bottom: 1px solid #969696;
            }
            .router-link-active {
                color: red;
            }
        }
    }
</style>