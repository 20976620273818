import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Layout from '@/views/layout/layout.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  //重定向
  {
    path: '',
    redirect: '/login'
  },
  //登录
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/login',
        component: () => import('@/views/login/login.vue')
      }
    ]
  },
  //退出
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/logout',
        component: () => import('@/views/logout.vue')
      }
    ]
  },
  //找回密码
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/password',
        component: () => import('@/views/login/password.vue')
      }
    ]
  },
  //库存首页
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/stock',
        component: () => import('@/views/layout/stock/stock.vue')
      }
    ]
  },
  // 车辆详情
  {
    path: '/stock',
    component: Layout,
    children: [
      {
        path: 'cardetails',
        name: 'detail',
        component: () => import('@/views/layout/stock/carDetails/car.vue')
      }
    ]
  },
  // 修改信息
  {
    path: '/stock',
    component: Layout,
    children: [
      {
        path: 'editinfo',
        component: () => import('@/views/layout/stock/editInfo/editInfo.vue')
      }
    ]
  },
  //添加库存
  {
    path: '/stock',
    component: Layout,
    children: [
      {
        path: 'addinfo',
        component: () => import('@/views/layout/stock/addinfo/addInfo.vue')
      }
    ]
  },
  //库存记录经销商
  {
    path: '/inventory',
    component: Layout,
    children: [
      {
        path: 'records',
        component: () => import('@/views/layout/stockRecords/stockRecords.vue')
      }
    ]
  },
  //库存记录经销商-车辆详情
  {
    path: '/inventory',
    component: Layout,
    children: [
      {
        path: 'carDetails',
        component: () => import('@/views/layout/stockRecords/carDetails.vue')
      }
    ]
  },
  //库存记录经销商-增加库存
  {
    path: '/inventory',
    component: Layout,
    children: [
      {
        path: 'addStock',
        component: () => import('@/views/layout/stockRecords/addStock.vue')
      }
    ]
  },
  //保修注册经销商
  {
    path: '/warranty',
    component: Layout,
    children: [
      {
        path: 'register',
        component: () => import('@/views/layout/warranty/warranty.vue')
      },
      {
        path: 'newregister',
        component: () => import('@/views/layout/warranty/newwarranty.vue')
      },
      //更新消费者注册信息
      {
        path: 'changeregister',
        component: () => import('@/views/layout/warranty/changewarranty.vue')
      },
      //车辆详情
      {
        path: 'info',
        component: () => import('@/views/layout/warranty/warrantyInfo.vue')
      },
    ]
  },
  //保修索赔经销商
  {
    path: '/claim',
    component: Layout,
    children: [
      {
        path: 'compensate',
        component: () => import('@/views/layout/compensate/compensate.vue')
      },
      // 正常保修
      {
        path: 'GNcompensate',
        component: () => import('@/views/layout/compensate/GNwarranty.vue')
      },
      // // 新车维保
      // {
      //   path: 'GPcompensate',
      //   component: () => import('@/views/layout/compensate/GPwarranty.vue')
      // },
      // 编辑索赔
      {
        path: 'editcompensate',
        component: () => import('@/views/layout/compensate/editwarranty.vue')
      },
      // 查看详情索赔
      {
        path: 'lookcompensate',
        component: () => import('@/views/layout/compensate/lookwarranty.vue')
      },
      // 索赔日志
      {
        path: 'logcompensate',
        component: () => import('@/views/layout/compensate/logwarranty.vue')
      },
      // 日志详情
      {
        path: 'logdetail',
        component: () => import('@/views/layout/compensate/logdetail.vue')
      },
      //索赔状态等待
      {
        path: 'waitlogdetail',
        component: () => import('@/views/layout/compensate/waitlogdetail.vue')
      },
      //索赔历史
      {
        path: 'claimHistory',
        component: () => import('@/views/layout/compensate/claimHistory.vue')
      },
      //索赔历史详情
      {
        path: 'historydetail',
        component: () => import('@/views/layout/compensate/historyDetail.vue')
      },
    ]
  },
  {
     path:'/retailer',
     component:Layout,
     children:[
        {
          path: 'records',
          component: () => import('@/views/layout/retailer/retailer_list.vue')
        },
        {
          path: 'newretailer',
          component: () => import('@/views/layout/retailer/newretailer.vue')
        },
        //更新消费者注册信息
        {
          path: 'changeretailer',
          component: () => import('@/views/layout/retailer/changeretailer.vue')
        },
        //车辆详情
        {
          path: 'info',
          component: () => import('@/views/layout/retailer/retailerInfo.vue')
        }
     ]
  },
  //维修保养
  {
    path: '/maintain',
    component: Layout,
    children: [
      //维修保养
      {
        path: 'service',
        component: () => import('@/views/layout/maintain/maintain.vue')
      },
      //新建维修保养
      {
        path: 'newservice',
        component: () => import('@/views/layout/maintain/newmaintain.vue')
      },
      //查看维保记录
      {
        path: 'lookservice',
        component: () => import('@/views/layout/maintain/lookmaintain.vue')
      },
    ]
  },
  //共享资料下载经销商
  {
    path: '/data',
    component: Layout,
    children: [
      {
        path: 'resources',
        component: () => import('@/views/layout/resources/resources.vue')
      }
    ]
  },
  //站内消息
  {
    path: '',
    component: Layout,
    children: [
      {
        path: 'message',
        component: () => import('@/views/layout/message/message.vue')
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: 'home',
        component: () => import('@/views/layout/home/home.vue')
      }
    ]
  },
  {
    path: "*",
      name: "NotFound",
      component: () => import("@/views/404/NotFound.vue"),
  },
   //召回功能
   {
    path: '/recall',
    component: Layout,
    children: [
      //召回列表
      {
        path: 'list',
        component: () => import('@/views/layout/recall/list.vue')
      },
      //召回详情
      {
        path: 'recallDetail',
        component: () => import('@/views/layout/recall/recallDetail.vue')
      },
      //召回处理
      {
        path: 'editRecall',
        component: () => import('@/views/layout/recall/editRecall.vue')
      },
      //查看召回处理详情
      {
        path: 'lookRecall',
        component: () => import('@/views/layout/recall/lookRecall.vue')
      },
      //查看召回历史列表
      {
        path: 'historicalRecall',
        component: () => import('@/views/layout/recall/historicalRecall.vue')
      },
    ]
  },
  //crm
  {
    path: '/crm',
    component: Layout,
    children: [
      //召回列表
      {
        path: 'list',
        component: () => import('@/views/layout/crm/crm.vue')
      },
    ]
  },
  //联系我们
  {
    path: '',
    component: Layout,
    children: [
      //召回列表
      {
        path: '/contact',
        component: () => import('@/views/layout/contact/index.vue')
      },
    ]
  },
  //附件
  {
    path: '',
    component: Layout,
    children: [
      //召回列表
      {
        path: '/annex',
        component: () => import('@/views/layout/annex/index.vue')
      },
    ]
  }
]

const router = new VueRouter({
  routes,
})
router.beforeEach((to,from,next)=>{
  let id = JSON.parse(localStorage.getItem('userInfo'))?JSON.parse(localStorage.getItem('userInfo')).id:''
  if (id||to.path=='/login'||to.path=='/password'||to.name=='NotFound') {
    next()
  }else{
    if (to.path.indexOf('/stock')!=-1) {
       router.push('/login')
    }else{
      window.location.href='https://oem.odesindustry.com/my-account'
    }
   
  }
 
})
export default router
